import React, { useState } from 'react';
import { TextField, Button, Paper, Typography, Box } from '@mui/material';
import axios from 'axios';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('/api/auth/login', { username, password });
            localStorage.setItem('token', response.data.token);
            alert('Login successful!');
            window.location.href = '/';
        } catch (error) {
            console.error('Login failed:', error.response?.data || error.message);
            alert('Invalid username or password');
        }
    };

    return (
        <Paper elevation={3} style={{ padding: '20px', maxWidth: '400px', margin: '50px auto' }}>
            <Typography variant="h5" gutterBottom>
                Admin Login
            </Typography>
            <form onSubmit={handleLogin}>
                <Box mb={2}>
                    <TextField
                        fullWidth
                        label="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        variant="outlined"
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        fullWidth
                        type="password"
                        label="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        variant="outlined"
                    />
                </Box>
                <Button type="submit" variant="contained" color="primary" fullWidth>
                    Login
                </Button>
            </form>
        </Paper>
    );
};

export default Login;