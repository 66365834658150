import React from 'react';
import { Typography, Paper, Box } from '@mui/material';


const Statistics = ({ data }) => {
  const dummyData = {
    sent: 100,
    errors: 5,
    read: 80,
    clicks: 20,
    unsubscribed: 2,
    spam: 1,
  };

  return (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Statistics
      </Typography>
      <Box mb={2}>
        <Typography variant="body1">Sent Emails: {dummyData.sent}</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body1">Email Errors: {dummyData.errors}</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body1">Read Emails: {dummyData.read}</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body1">Click Rate: {dummyData.clicks}</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body1">Unsubscribed: {dummyData.unsubscribed}</Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body1">Spam Reports: {dummyData.spam}</Typography>
      </Box>
    </Paper>
  );
};

export default Statistics;
