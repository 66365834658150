import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  Paper, Typography, Box, Button, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Dialog, DialogContent, DialogTitle, 
  DialogActions, TextField, Select, MenuItem, IconButton, Pagination 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import * as XLSX from 'xlsx';

const CATEGORIES = [
  'Construction', 
  'IT', 
  'Recruiting', 
  'Marketing', 
  'Finance', 
  'Healthcare', 
  'Education',
  'Manufacturing',
  'Retail',
  'Hospitality',
  'Transportation',
  'Telecommunications',
  'Energy',
  'Consulting'
];

const ContactList = () => {
 const { groupName } = useParams();  
  const navigate = useNavigate();

  // Состояния для списка групп
  const [groups, setGroups] = useState([]);
  
  // Состояния для импорта
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [importedContacts, setImportedContacts] = useState([]);
  const [importGroupName, setImportGroupName] = useState('');
  const [importCategory, setImportCategory] = useState('');

  // Состояние диалога удаления группы
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({open: false, groupId: null});

  // Состояния для деталей группы (контактов)
  const [contacts, setContacts] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({
    email: '',
    status: ''
  });
  const [page, setPage] = useState(1);
  const rowsPerPage = 50;

  useEffect(() => {
    if (!groupName) {
      // Если нет groupId, значит мы на странице со списком групп
      fetchGroups();
    } else {
      // Если есть groupId, значит мы на странице деталей группы
      fetchContacts();
    }
  }, [groupName]);

  // Получаем список групп
  const fetchGroups = async () => {
    try {
      const response = await axios.get('/api/contacts/groups');
      // Ожидается, что сервер вернет массив объектов со структурой:
      // { _id, groupName, category, emailsCount }
      setGroups(response.data);
    } catch (error) {
      console.error('Failed to fetch groups', error);
    }
  };

  // Получаем контакты для конкретной группы
  const fetchContacts = async () => {
    try {
      const response = await axios.get(`/api/contacts?groupId=${groupName}`);
      setContacts(response.data);
    } catch (error) {
      console.error('Failed to fetch contacts', error);
    }
  };

  // Обработка выбора файла для импорта групп
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (evt) => {
      const data = new Uint8Array(evt.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const imported = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const formattedContacts = imported.map(row => ({
        email: row[0]
      })).filter(c => c.email);
      setImportedContacts(formattedContacts);
      setOpenImportDialog(true);
    };
    reader.readAsArrayBuffer(file);
  };

  // Импорт контактов (создание новой группы)
  const handleImportSubmit = async () => {
    if (!importGroupName || !importCategory) {
      alert('Please specify group name and category.');
      return;
    }
    if (importedContacts.length === 0) {
      alert('No contacts to import.');
      return;
    }
    try {
      await axios.post('/api/contacts/import', {
        groupName: importGroupName,
        category: importCategory,
        contacts: importedContacts
      });
      setOpenImportDialog(false);
      setImportedContacts([]);
      setImportGroupName('');
      setImportCategory('');
      alert('Contacts imported successfully.');
      fetchGroups();
    } catch (error) {
      console.error('Failed to import contacts:', error);
      alert('Failed to import contacts. Please try again.');
    }
  };

  // Удаление группы
  const handleDeleteGroup = async () => {
    if (!confirmDeleteDialog.groupId) return;
    try {
      await axios.delete(`/api/contacts/groups/${confirmDeleteDialog.groupId}`);
      setConfirmDeleteDialog({open: false, groupId: null});
      fetchGroups();
    } catch (error) {
      console.error('Failed to delete group', error);
      alert('Failed to delete group.');
    }
  };

  // Удаление контакта
  const handleDeleteContact = async (id) => {
    try {
      await axios.delete(`/api/contacts/${id}`);
      fetchContacts();
    } catch (error) {
      console.error('Failed to delete contact', error);
    }
  };

  // Фильтры для контактов
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  };

  // Фильтрация контактов
  const filteredContacts = contacts.filter(contact =>
    contact.email.includes(search) &&
    (filter.email ? contact.email.includes(filter.email) : true) &&
    (filter.status ? contact.status.includes(filter.status) : true)
  );

  const paginatedContacts = filteredContacts.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  // UI для списка групп
  const renderGroupsUI = () => (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Contact Groups</Typography>
        <Button variant="contained" component="label" color="primary">
          Import
          <input type="file" accept=".xlsx, .xls" hidden onChange={handleFileSelect} />
        </Button>
      </Box>
      
      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Group Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Number of Emails</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
  {groups.map(group => (
    <TableRow 
      key={group.groupName}
      onClick={() => navigate(`/contacts/${group.groupName}`)} // Используем groupName вместо group._id
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#f5f5f5',
        }
      }}
    >
      <TableCell>{group.groupName}</TableCell>
      <TableCell>{group.category}</TableCell>
      <TableCell>{group.emailsCount}</TableCell>
      <TableCell>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setConfirmDeleteDialog({open:true, groupId: group.groupName});
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ))}
</TableBody>
        </Table>
      </TableContainer>

      {/* Диалог импорта */}
      <Dialog open={openImportDialog} onClose={() => setOpenImportDialog(false)}>
        <DialogTitle>Import Contacts</DialogTitle>
        <DialogContent>
          <Typography>
            You are about to import {importedContacts.length} contacts.
          </Typography>
          <TextField
            fullWidth
            margin="dense"
            label="Group Name"
            value={importGroupName}
            onChange={(e) => setImportGroupName(e.target.value)}
          />
          <Select
            fullWidth
            value={importCategory}
            onChange={(e) => setImportCategory(e.target.value)}
            displayEmpty
            style={{marginTop: '15px'}}
          >
            <MenuItem value="" disabled>Select Category</MenuItem>
            {CATEGORIES.map(cat => (
              <MenuItem key={cat} value={cat}>{cat}</MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenImportDialog(false)}>Cancel</Button>
          <Button onClick={handleImportSubmit} color="primary">Add Contacts</Button>
        </DialogActions>
      </Dialog>

      {/* Диалог подтверждения удаления группы */}
      <Dialog open={confirmDeleteDialog.open} onClose={() => setConfirmDeleteDialog({open:false, groupId:null})}>
        <DialogTitle>Delete Group</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this group?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteDialog({open:false, groupId:null})}>Cancel</Button>
          <Button color="secondary" onClick={handleDeleteGroup}>Delete</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );

  // UI для деталей группы (контактов)
  const renderContactsUI = () => (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Contacts in Group
      </Typography>
      <Box mb={2} display="flex">
        <TextField label="Search Email" value={search} onChange={handleSearchChange} fullWidth />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <TextField
                  name="email"
                  label="Filter by Email"
                  value={filter.email}
                  onChange={handleFilterChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="status"
                  label="Filter by Status"
                  value={filter.status}
                  onChange={handleFilterChange}
                  variant="outlined"
                  size="small"
                  select
                  fullWidth
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="subscribed">Subscribed</MenuItem>
                  <MenuItem value="unsubscribed">Unsubscribed</MenuItem>
                </TextField>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedContacts.map(contact => (
              <TableRow key={contact._id}>
                <TableCell>{contact.email}</TableCell>
                <TableCell>{contact.status}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDeleteContact(contact._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {paginatedContacts.length === 0 && (
              <TableRow>
                <TableCell colSpan={3}>No contacts found.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={2} display="flex" justifyContent="center">
        <Pagination 
          count={Math.ceil(filteredContacts.length / rowsPerPage)} 
          page={page} 
          onChange={(e, value) => setPage(value)}
        />
      </Box>
    </Paper>
  );

  return groupName ? renderContactsUI() : renderGroupsUI();
};

export default ContactList;
