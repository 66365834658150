import React, { useState, useEffect } from 'react';
import { TextField, Button, MenuItem, Typography, Paper, Box, Select, InputLabel, FormControl, Checkbox, ListItemText } from '@mui/material';
import axios from 'axios';


const EmailSender = () => {
  const [emailData, setEmailData] = useState({
    campaignName: '',
    groups: [],
    senderEmail: '',
    subject: '',
    message: '',
    file: null,
  });
  const [groups, setGroups] = useState([]);
  const [domains, setDomains] = useState([]);
  const [verifiedEmails, setVerifiedEmails] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchGroups();
    fetchDomains();
    fetchVerifiedEmails();
  }, []);

  const fetchGroups = async () => {
  try {
    const response = await axios.get('/api/contacts/groups');
    // ?????? ?? ????? ?? ?????, ? ????????? ?????? ??? ????:
    setGroups(response.data); 
  } catch (error) {
    console.error('Failed to fetch groups', error);
  }
};

const getEmailsFromGroups = async (selectedGroups) => {
  try {
    const emails = [];
    for (const groupName of selectedGroups) {
      const response = await axios.get(`/api/contacts/groups/${groupName}`);
      if (response.data && response.data.length > 0) {
        emails.push(...response.data);
      } else {
        console.warn(`No emails found for group: ${groupName}`);
      }
    }
    return emails;
  } catch (error) {
    console.error('Failed to fetch emails from groups:', error);
    return [];
  }
};
  const fetchDomains = async () => {
    try {
      const response = await axios.get('/api/domains');
      setDomains(response.data);
    } catch (error) {
      console.error('Failed to fetch domains', error);
    }
  };

const fetchVerifiedEmails = async () => {
  try {
    const response = await axios.get('/api/emails');
    const verified = response.data
      .filter(email => email.isVerified)
      .map(email => email.email);
    console.log('Filtered verified emails:', verified);
    setVerifiedEmails(verified);
  } catch (error) {
    console.error('Failed to fetch verified emails', error);
    alert('Failed to load verified sender emails. Please try again later.');
  }
};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleGroupChange = (event) => {
    const { value } = event.target;
    setEmailData(prevData => ({
      ...prevData,
      groups: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleFileChange = (e) => {
    setEmailData(prevData => ({ ...prevData, file: e.target.files[0] }));
  };


 const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);

  if (!emailData.campaignName || !emailData.senderEmail || !emailData.subject || !emailData.message || emailData.groups.length === 0) {
    alert('Please fill in all required fields.');
    setLoading(false);
    return;
  }

  if (!verifiedEmails.includes(emailData.senderEmail)) {
    alert('The sender email is not verified. Please use a verified email.');
    setLoading(false);
    return;
  }

  try {
    const recipients = await getEmailsFromGroups(emailData.groups);

    if (recipients.length === 0) {
      alert('No email addresses found in the selected groups.');
      setLoading(false);
      return;
    }

    // ??????? ?????? ?????? ?????? FormData
    const data = {
      campaignName: emailData.campaignName,
      recipients: recipients,
      senderEmail: emailData.senderEmail,
      subject: emailData.subject,
      message: emailData.message,
    };

    // ???????? ?????? ????? ?????????
    console.log('Sending data:', data);

    // ?????????? JSON-??????
    const response = await axios.post('/api/send-email', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    console.log('Response from server:', response.data);
    alert('Email sent successfully');
  } catch (error) {
    console.error('Failed to send email:', error.response?.data || error.message);
    alert(`Failed to send email: ${error.response?.data?.error || error.message}`);
  } finally {
    setLoading(false);
  }
};


  return (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Send Email
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            fullWidth
            label="Campaign Name"
            name="campaignName"
            value={emailData.campaignName}
            onChange={handleChange}
            variant="outlined"
          />
        </Box>
        <Box mb={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Groups</InputLabel>
            <Select
  multiple
  value={emailData.groups}
  onChange={handleGroupChange}
  label="Groups"
  renderValue={(selected) => {
    if (selected.length === 0) {
      return 'No groups selected';
    }
    // ??????? ??????? ????? ?? ?? ??????
    const selectedGroups = groups.filter(g => selected.includes(g.groupName));

    // ??????? ????? ????? contacts
    const totalContacts = selectedGroups.reduce((sum, g) => sum + g.emailsCount, 0);

    // ???????? ?????? ???? ?????
    const groupNames = selectedGroups.map(g => g.groupName).join(', ');

    // ?????????? ?????? ? ??????? ????? ? ????? ?????? ?????????
    return `${groupNames} (Total:${totalContacts})`;
  }}
>
              {groups.length > 0 ? (
  groups.map((group) => (
    <MenuItem key={group.groupName} value={group.groupName}>
      <Checkbox checked={emailData.groups.indexOf(group.groupName) > -1} />
      <ListItemText primary={`${group.groupName} (${group.emailsCount})`} />
    </MenuItem>
  ))
) : (
  <MenuItem disabled>No groups found</MenuItem>
)}
            </Select>
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Sender Email</InputLabel>
            <Select
              name="senderEmail"
              value={emailData.senderEmail}
              onChange={handleChange}
              label="Sender Email"
            >
              {verifiedEmails.length > 0 ? (
                verifiedEmails.map((email) => (
                  <MenuItem key={email} value={email}>
                    {email}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No verified emails found</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
        <Box mb={2}>
          <TextField
            fullWidth
            label="Subject"
            name="subject"
            value={emailData.subject}
            onChange={handleChange}
            variant="outlined"
          />
        </Box>
        <Box mb={2}>
          <TextField
            fullWidth
            label="Message"
            name="message"
            value={emailData.message}
            onChange={handleChange}
            variant="outlined"
            multiline
            rows={4}
          />
        </Box>
        <Box mb={2}>
          <Button variant="contained" component="label">
            Upload File
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          {emailData.file && (
            <Typography variant="body2" style={{ marginTop: '10px' }}>
              {emailData.file.name}
            </Typography>
          )}
        </Box>
        <Button type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? 'Sending...' : 'Send Email'}
        </Button>
      </form>
    </Paper>
  );
};

export default EmailSender;