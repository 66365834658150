import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import axios from 'axios';
import PrivateRoute from './components/PrivateRoute';
import Login from './components/Login';
import Home from './components/Home';
import EmailSender from './components/EmailSender';
import ContactList from './components/ContactList';
import Security from './components/Security';
import Domains from './components/Domains';
import Statistics from './components/Statistics';

const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  return !!token; // ?????????? true, ???? ????? ??????????
};

function App() {
  const authenticated = isAuthenticated();

  return (
    <Router>
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Email Marketing
            </Typography>
            {/* ?????? "Home" ???????? ???? */}
            <Button color="inherit" component={Link} to="/">Home</Button>

            {/* ?????????? ????????? ?????? ?????? ??? ??????????????????? ????????????? */}
            {authenticated && (
              <>
                <Button color="inherit" component={Link} to="/send-email">Send Email</Button>
                <Button color="inherit" component={Link} to="/contacts">Contact List</Button>
                <Button color="inherit" component={Link} to="/statistics">Statistics</Button>
                <Button color="inherit" component={Link} to="/domains">Domains</Button>
                <Button color="inherit" component={Link} to="/security">Security</Button>
                <Button
                  color="inherit"
                  sx={{
    marginLeft: '10px',
    backgroundColor: '#FFFFFF',
    color: '#3f51b5',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
    }}
                  onClick={() => {
                    localStorage.removeItem('token');
                    window.location.href = '/';
                  }}
                >
                  Logout
                </Button>
              </>
            )}

            {/* ???? ???????????? ?? ????????????????, ?????????? ?????? "Login" */}
            {!authenticated && (
              <Button color="inherit" component={Link} to="/login" sx={{
    marginLeft: '10px',
    backgroundColor: '#FFFFFF',
    color: '#3f51b5',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },}} 
    
    onClick={() => {
                    localStorage.setItem('token');
                    window.location.href = '/';
                  }}
    >Login</Button>
            )}
          </Toolbar>
        </AppBar>
        <div style={{ padding: '20px' }}>
          <Routes>
  <Route path="/" element={<Home />} />
  <Route path="/send-email" element={<PrivateRoute element={<EmailSender />} />} />
  <Route path="/contacts" element={<PrivateRoute element={<ContactList />} />} />
  <Route path="/contacts/:groupName" element={<PrivateRoute element={<ContactList />} />} />
  <Route path="/statistics" element={<PrivateRoute element={<Statistics />} />} />
  <Route path="/domains" element={<PrivateRoute element={<Domains />} />} />
  <Route path="/security" element={<PrivateRoute element={<Security />} />} />
  <Route path="/login" element={<Login />} />
</Routes>

        </div>
      </div>
    </Router>
  );
}

// ????????? Axios ??? ???????? ???? ? ?????????
axios.defaults.withCredentials = true;

export default App;